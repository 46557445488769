<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="listRoles"
      :server-items-length="rolesMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      show-expand
      single-expand
      :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('role-update')"
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("roles") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <delete-role v-if="$admin.can('role-delete')"></delete-role>
          <edit-role v-if="$admin.can('role-update')"></edit-role>
        </v-toolbar>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="options.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  components: {
    EditRole: () => import("./dialogs/EditRole.vue"),
    DeleteRole: () => import("./dialogs/DeleteRole.vue"),
  },

  computed: {
    ...mapGetters({
      listRoles: "roles/listOwnedRoles",
      rolesMeta: "roles/meta",
    }),
  },

  watch: {
    options: {
      handler: debounce(async function () {
        this.is_loading_role = true;
        await this.$store.dispatch("roles/list", this.options).then(() => {
          this.is_loading_role = false;
          if (this.options.page > (this.rolesMeta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
      }, 1000),
      deep: true,
    },
  },

  data() {
    return {
      options: {},
      is_loading_role: false,
      headers: [
        {
          text: this.$t("id"),
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },

        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.$store.dispatch("roles/openEditForm", item);
    },
  },
};
</script>
